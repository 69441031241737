export const {
  IS_ACTIVE,
  IS_OPEN,
  // IS_VISIBLE,
  IS_READY,
  // IS_LOADING,
  IS_LOADED,
  // HAS_FOCUS,
  // IS_ANIMATE,
  // IS_ANIMATING,
  // IS_ANIMATED,
  // IS_HIDDEN,
  NO_SCROLL,
  NO_TOUCH,
  // HAS_ERROR,
  // HAS_TEXT,
  // IS_VALID,
  // IS_FIXED,
} = {
  IS_ACTIVE: 'active',
  IS_OPEN: 'open',
  // IS_VISIBLE: 'visible',
  IS_READY: 'is-ready',
  // IS_LOADING: 'loading',
  IS_LOADED: 'loaded',
  // HAS_FOCUS: 'focus',
  // IS_ANIMATE: 'animate',
  // IS_ANIMATING: 'animating',
  // IS_ANIMATED: 'animated',
  // IS_HIDDEN: 'hidden',
  NO_SCROLL: 'no-scroll',
  NO_TOUCH: 'no-touch',
  // HAS_ERROR: 'error',
  // HAS_TEXT: 'has-text',
  // IS_VALID: 'valid',
  // IS_FIXED: 'fixed',
}
